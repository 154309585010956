.buttons__signin {
	padding: 0.5em 2em;
	border-radius: 999px;
	border: 1px solid #4885ed;
	background: #4885ed;
	color: #fff;
	cursor: pointer;
	transition: all 0.5s ease-in-out;
	font-weight: 600;
}

.buttons__signin:hover {
	background: #fff;
	color: #4885ed;
}

.buttons__signin > i {
	margin-left: 10px;
}

.buttons__signout {
	color: rgb(155, 0, 0);
	font-weight: 600;
	border: 1px solid rgb(155, 0, 0);
	box-sizing: border-box;
	padding: 0.5em 1em;
	background: transparent;
}
