.manageAffiliates {
	display: block;
	padding: 15px 0;
	box-sizing: border-box;
}
.manageAffiliates .manageAffiliates__list {
	display: block;
	margin: 15px auto;
	width: 100%;
}

.manageAffiliates .manageAffiliates__list .list__header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 2px solid #333;
}

.manageAffiliates .manageAffiliates__list .list__header .header__title {
	width: 200px;
	text-align: center;
	font-weight: 500;
	color: #333;
	font-size: 18px;
}

.manageAffiliates .manageAffiliates__list .list__body .body__row {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px 0;
	cursor: pointer;
}

.manageAffiliates .manageAffiliates__list .list__body .body__row:hover {
	background: rgba(0, 0, 0, 0.05);
}

.manageAffiliates .manageAffiliates__list .list__body .body__row .body__value {
	width: 200px;
	text-align: center;
}

.manageAffiliates
	.manageAffiliates__list
	.list__body
	.body__row
	.body__value.status
	.status__approved {
	color: rgb(0, 155, 0);
}

.manageAffiliates
	.manageAffiliates__list
	.list__body
	.body__row
	.body__value.status
	.status__denied {
	color: rgb(155, 0, 0);
}

.manageAffiliates
	.manageAffiliates__list
	.list__body
	.body__row
	.body__value.status
	.status__pending {
	color: rgb(255, 155, 0);
}

.manageAffiliates .manageAffiliates__view {
	display: block;
	width: 100%;
	box-sizing: border-box;
	padding: 15px 0;
}

.manageAffiliates .manageAffiliates__view .view__container {
	display: flex;
	width: 100%;
	justify-content: space-between;
	box-sizing: border-box;
}

.manageAffiliates .manageAffiliates__view .view__container .view__left {
	width: 60%;
}
.manageAffiliates .manageAffiliates__view .view__container .view__left .left__row {
	display: flex;
	align-items: center;
	padding: 10px 0;
	border-bottom: 1px dashed #333;
}
.manageAffiliates .manageAffiliates__view .view__container .view__left .left__row .row__title {
	width: 13em;
	font-weight: 500;
	color: #333;
}

.manageAffiliates
	.manageAffiliates__view
	.view__container
	.view__left
	.left__row
	.row__value
	input {
	padding: 3px 5px;
	box-sizing: border-box;
}
.manageAffiliates .manageAffiliates__view .view__container .view__right {
	width: 40%;
	padding: 0 45px;
	box-sizing: border-box;
}

.manageAffiliates .manageAffiliates__view .view__container .view__right .button {
	cursor: pointer;
}

.manageAffiliates .manageAffiliates__view .view__container .view__right .button.approve {
	background: rgb(0, 155, 0);
	color: #fff;
	border-color: rgb(0, 155, 0);
}

.manageAffiliates .manageAffiliates__view .view__container .view__right .button.approve:hover {
	background: #fff;
	color: rgb(0, 155, 0);
}

.manageAffiliates .manageAffiliates__view .view__container .view__right .button.deny {
	background: rgb(155, 0, 0);
	color: #fff;
	border-color: rgb(155, 0, 0);
}

.manageAffiliates .manageAffiliates__view .view__container .view__right .button.deny:hover {
	background: #fff;
	color: rgb(155, 0, 0);
}

.manageAffiliates .manageAffiliates__view .view__container .view__right .button.send_message {
	background: #fff;
	color: #333;
}

.manageAffiliates .manageAffiliates__view .view__container .view__right .button.send_message:hover {
	opacity: 0.7;
}

.manageAffiliates .manageAffiliates__view .view__container .view__right .decision__buttons {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 30px;
}

.manageAffiliates
	.manageAffiliates__view
	.view__container
	.view__right
	.decision__buttons
	> .button {
	flex-grow: 1;
	margin: 0;
	width: 50%;
}

.manageAffiliates
	.manageAffiliates__view
	.view__container
	.view__right
	.decision__buttons
	> .button:first-child {
	margin-right: 10px;
}

.manageAffiliates
	.manageAffiliates__view
	.view__container
	.view__right
	.decision__buttons
	> .button:last-child {
	margin: 0;
}

.approval__dialogue {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	padding: 30px;
	background: #fff;
	box-shadow: 0 3px 10px rgba(0, 0, 0, 0.25);
	border-radius: 10px;
	box-sizing: border-box;
}

.approval__dialogue p {
	margin: 15px 0;
}

.approval__dialogue input {
	border: 1px solid #333;
	padding: 10px;
	width: 100%;
	box-sizing: border-box;
}

.manageAffiliates__sendMessage {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 500px;
	background: #fff;
	border-radius: 10px;
	box-shadow: 0 5px 10px rgba(0, 0, 0, 0.25);
	padding: 15px;
	box-sizing: border-box;
	display: flex;
	justify-content: space-between;
}

.sendMessage__left {
	width: 30%;
	display: flex;
	flex-direction: column;
	padding: 15px 0;
}

.sendMessage__left h3 {
	margin-bottom: 15px;
	color: rgb(0, 155, 0);
}

.sendMessage__left button {
	margin: 5px 0;
	padding: 0.5em 0;
	cursor: pointer;
}

.sendMessage__right {
	width: 70%;
	margin-left: 15px;
	padding: 15px 0;
	box-sizing: border-box;
}

.sendMessage__right input {
	width: 100%;
	padding: 5px;
	box-sizing: border-box;
	margin-bottom: 2px;
}

.sendMessage__right textarea {
	width: 100%;
	min-height: 10em;
	padding: 10px;
	box-sizing: border-box;
}
