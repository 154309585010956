.countDownTimer {
	display: flex;
	width: 100%;
	align-items: center;
}

.countDownTimer span {
	text-align: center;
	margin: 0 10px;
}

.countDownTimer span .timeComponent__value {
    font-family: 'Zen Dots', cursive;
	display: block;
	font-size: 20px;
	color: #fff;
	font-weight: 500;
	margin-bottom: -5px;
}

.countDownTimer span .timeComponent__label {
	color: rgba(255, 255, 255, 0.8);
	text-transform: uppercase;
	font-size: 15px;
}
