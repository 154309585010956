.nonUserSteps {
	display: flex;
	align-items: center;
	flex-direction: column;
	box-sizing: border-box;
	padding: 15px 30px;
}

.nonUserSteps h1 {
	text-align: center;
}

.nonUserSteps__list ul {
	list-style-type: none;
	display: flex;
	justify-content: center;
	align-items: top;
	margin: 30px auto;
}

.nonUserSteps__list ul li {
	width: 300px;
	height: auto;
	margin: 0 20px;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	cursor: pointer;
}

.nonUserSteps__list ul li .list__itemHeader {
	display: flex;
	align-items: center;
	flex-direction: column;
}

.list__itemHeader .itemHeader__number {
	box-sizing: border-box;
	padding: 5px 20px;
	font-size: 26px;
	font-weight: 600;
	background: rgb(0, 155, 0);
	color: #fff;
	border-right: 2px solid rgb(0, 155, 0);
	border-bottom: 2px solid rgb(0, 155, 0);
	text-align: center;
	border-radius: 50%;
	height: 2em;
	width: 2em;
	line-height: 1.5em;
}

.list__itemHeader .itemHeader__title {
	font-size: 26px;
	font-weight: 600;
	width: 100%;
	text-align: center;
}

.list__itemBody {
	padding: 15px;
	text-align: center;
}

.list__itemBody p {
	margin-bottom: 10px;
}

.nonUserSteps__details {
	width: 100%;
}

.nonUserSteps__details h2 {
	width: 100%;
	background: rgb(0, 155, 0);
	color: #fff;
	text-align: center;
	padding: 5px 0;
}

div[class^="details__step"] {
	display: block;
	padding: 15px;
	box-sizing: border-box;
}

.details__stepOne > i.fab.fa-google {
	font-size: 72px;
	color: #4885ed;
	border: 3px solid rgb(200, 0, 0);
	padding: 15px;
	border-radius: 5px;
	float: left;
	margin: 0 40px 15px 0;
}

div[class^="details__step"] p {
	font-size: 17px;
	line-height: 2em;
	margin-bottom: 20px;
}

.details__stepOne p .button {
	margin: 15px auto;
}

.issuesContainer {
	display: flex;
	justify-content: space-between;
	align-items: top;
	margin-top: 45px;
}

.issuesContainer__issue {
	margin: 0 15px;
	padding: 15px;
	box-sizing: border-box;
	text-align: center;
}
