.sub__alert {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 400px;
	background: #fff;
	color: #333;
	text-align: center;
	border-radius: 10px;
	box-sizing: border-box;
	box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.5);
	overflow: hidden;
	padding-top: 20px;
	z-index: 90;
}

.sub__alertBG{
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	width: 100%;
	background: rgba(0,0,0,0.5);
	z-index: 89;
	cursor: pointer;
}

.sub__alert h2{
	margin-bottom: 10px;
	color: rgb(155,0,0);
}

.sub__alert p{
	padding: 0 15px;
}

.sub__alertClose {
	padding: 0.5em 2em;
	background: #333;
	color: #fff;
	font-weight: 500;
	margin-top: 30px;
	cursor: pointer;
	transition: all 0.5s ease-in-out;
}

.sub__alertClose:hover {
	opacity: 0.5;
}

.subUser {
	display: block;
	box-sizing: border-box;
	padding: 10px;
	text-align: center;
	margin-bottom: 30px;
}

.subManage {
	box-sizing: border-box;
	padding: 10px 0;
}

.subSignUp {
	display: flex;
	box-sizing: border-box;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: center;
	padding: 10px 0;
	margin-bottom: 30px;
}

.subSignUp h2,
.subManage h2 {
	width: 100%;
	padding: 10px 0;
	text-align: center;
	background: #333;
	color: #fff;
}

.subSignUp__buttonGroup {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 15px 0;
}

.subSignUp__buttonGroup button {
	position: relative;
	width: 200px;
	height: 150px;
	margin: 0 10px;
	padding: 0;
	cursor: pointer;
	border-radius: 10px;
	border: none;
	overflow: hidden;
	transition: all 200ms ease-in-out;
}

.subSignUp__buttonGroup button:hover {
	opacity: 0.7;
}

.subSignUp__buttonGroup button.selected {
	opacity: 0.5;
}

.subSignUp__buttonGroup button.clicked {
	box-shadow: 0 3px 10px rgba(0, 0, 0, 0.25);
	background: rgba(0, 155, 0, 0.15);
}

.subSignUp__buttonGroup button:focus {
	outline: none;
}

.subSignUp__buttonGroup button .buttonGroup__title {
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	/*background: #0397b8;*/
	background: #006900;
	box-sizing: border-box;
	padding: 10px 40px;
	font-weight: 700;
	color: #fff;
	font-size: 20px;
}

.subSignUp__buttonGroup button .buttonGroup__desc {
	padding: 10px 0;
	display: block;
	width: 100%;
	font-size: 17px;
}

.subSignUp__buttonGroup button .buttonGroup__desc .desc__price {
	display: block;
	font-size: 26px;
	color: #555;
	font-weight: 700;
	margin-top: 20px;
}

.subSignUp__buttonGroup button .buttonGroup__desc .desc__price.strikethrough {
	text-decoration: line-through;
	color: #888888;
}

.subSignUp__buttonGroup button .buttonGroup__desc .desc__frequency {
	display: block;
}

.StripeElement input {
	border: 1px solid #333;
}

.subList {
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	margin-bottom: 30px;
	padding: 0 30px;
}

.subItem {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 15px;
}

.subItem:nth-child(even) {
	background: rgba(0, 0, 0, 0.05);
}
.subItem:nth-child(1) {
	background: #fff;
	font-weight: 700;
}

.subItem.noSub {
	background: #fff;
	font-weight: 700;
	color: rgb(200, 0, 0);
	font-size: 20px;
	text-align: center;
	justify-content: space-evenly;
	flex-direction: column;
}

.subItem.noSub .description {
	color: #333;
	font-weight: 500;
	font-size: 17px;
	margin-top: 15px;
}

.subItem__id {
	flex: 0.3;
}

.subItem__nextPayment {
	flex: 0.125;
}

.subItem__interval {
	flex: 0.15;
}

.subItem__dueDate {
	flex: 0.2;
}

.subItem__status {
	flex: 0.1;
}

.subItem__buttons {
	flex: 0.1;
	display: flex;
	justify-content: space-evenly;
	align-items: center;
}

.buttons__cancel {
	background: transparent;
	border: 1px solid rgba(155, 0, 0, 1);
	padding: 0.5em 2em;
	box-sizing: border-box;
	color: rgb(155, 0, 0);
	border-radius: 3px;
	transition: all 0.5s ease-in-out;
}

.buttons__cancel:hover {
	background: rgba(155, 0, 0, 0.5);
	color: #fff;
	border-color: rgba(155, 0, 0, 0.5);
}

.stripeCardElement {
	width: 560px;
	display: block;
	margin: 15px auto;
	border: 1px solid #333;
	color: #333;
	padding: 20px 0 0 0;
	border-radius: 10px;
	box-sizing: border-box;
	overflow: hidden;
}

.stripeCardElement__container {
	padding: 15px;
}

.stripeCardElement__submit {
	display: block;
	margin: 15px auto 0 auto;
	padding: 0.5em 2em;
	background: rgb(0, 155, 0);
	color: #fff;
	border: none;
	width: 100%;
	font-size: 18px;
	font-weight: 500;
}

.stripeCardElement__submit:disabled{
	background: #999999;
	cursor: not-allowed;
}

.signUp__reminder {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 460px;
	box-shadow: 0 3px 10px rgba(255, 255, 255, 0.5);
	background: #fff;
	border-radius: 10px;
	overflow: hidden;
	z-index: 99;
}

.signUp__reminderBg {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(0, 0, 0, 0.85);
	z-index: 98;
}

.signUp__reminder .reminder__close {
	position: absolute;
	top: 10px;
	right: 10px;
	height: 20px;
	width: 20px;
	border: 1px solid #fff;
	border-radius: 50%;
	text-align: center;
	line-height: 20px;
	cursor: pointer;
	transition: all 200ms ease-in-out;
	font-size: 14px;
	color: #fff;
}

.signUp__reminder .reminder__close:hover {
	opacity: 0.7;
}

.signUp__reminder h3 {
	font-size: 36px;
	text-align: center;
	background: rgb(155, 0, 0);
	color: #fff;
	padding: 15px 0;
	box-sizing: border-box;
	width: 100%;
}

.signUp__reminder .reminder__body {
	padding: 15px 15px 30px 15px;
	box-sizing: border-box;
	font-size: 17px;
}

.app.dark .signUp__reminder .reminder__body p {
	color: #333333 !important;
}

.reminder__body .reminder__bodyEmail {
	color: rgb(0, 155, 0);
	font-weight: 500;
}

.reminder__body p {
	margin-bottom: 15px;
}

.userPortal__button {
	padding: 0.5em 0;
	width: 250px;
	text-align: center;
	margin-top: 15px;
	background: rgb(0, 155, 0);
	border: 1px solid rgb(0, 155, 0);
	color: #fff;
	font-weight: 700;
	border-radius: 5px;
	box-shadow: 0 3px 5px rgba(0, 0, 0, 0.25);
	cursor: pointer;
	font-size: 18px;
	transition: all 200ms ease-in-out;
}

.userPortal__button:hover {
	background: #fff;
	color: rgb(0, 155, 0);
}
