.nonUserHome__banner {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 15px 0 15px;
	box-sizing: border-box;
	height: 90vh;
}

.banner__text {
	flex: 0.4;
	box-sizing: border-box;
	padding: 15px;
	max-width: 30em;
}

.banner__text h1 {
	margin: 0;
}

.banner__image {
	display: block;
	position: relative;
	flex: 0.6;
	min-height: 450px;
}

.image__one,
.image__two {
	position: absolute;
	box-shadow: 0 3px 10px rgba(0, 0, 0, 0.25);
	border-radius: 10px;
}

.image__one {
	top: 0;
	left: 40%;
	z-index: 1;
	transform: translateX(-50%);
}

.image__two {
	top: 75px;
	left: calc(40% + 150px);
	z-index: 2;
	transform: translateX(-50%);
}

.button {
	display: block;
	padding: 0.5em 2em;
	text-align: center;
	box-sizing: border-box;
	background: #333;
	margin-top: 30px;
	color: #fff;
	text-decoration: none;
	font-weight: 600;
	font-size: 18px;
	border: 1px solid #333;
	border-radius: 5px;
	transition: all 200ms ease-in-out;
}

.button:hover {
	background: #ffffff;
	color: #333;
	border-color: #ffffff;
}

.app.dark .button {
	background: #ffffff;
	color: #333333;
}

.app.dark .button:hover {
	background: transparent;
	color: #ffffff;
}

.banner__text center {
	margin: 15px auto;
	font-weight: 700;
	font-style: italic;
	font-size: 18px;
}

.banner__text .buttons__signin {
	width: 75%;
	font-size: 18px;
	display: block;
	margin: 0 auto;
}
