.userHome h1 {
	text-align: center;
}

.userHome__checklist {
	width: 700px;
	border: 1px solid #333;
	border-radius: 10px;
	padding: 15px;
	box-sizing: border-box;
	display: block;
	margin: 30px auto;
	text-align: center;
}

.userHome__checklist h2 {
	margin: 0 0 15px 0;
}

.userHome__checklistProgressBar {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 500px;
	height: 30px;
	padding: 0;
	margin: 15px auto;
	border-radius: 999px;
	overflow: hidden;
}
.bars__complete {
	height: 30px;
	width: 100px;
	background: rgb(0, 155, 0);
	line-height: 30px;
	color: #fff;
	text-align: right;
	padding: 0 5px;
	box-sizing: border-box;
	font-size: 14px;
	font-weight: 500;
}

.bars__incomplete {
	height: 30px;
	width: 100px;
	background: rgba(0, 0, 0, 0.15);
}

.userHome__checklistProgressList {
	display: flex;
	align-items: center;
	flex-direction: column;
}

.checkListProgressList__list {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
}

.userHome__checklistProgressList a {
	text-decoration: none;
	color: #333;
}

.checkListProgressList__item {
	padding: 0.5em 2em;
	box-sizing: border-box;
	text-align: center;
	font-size: 18px;
	border: 1px solid #999;
	border-radius: 5px;
	margin: 10px;
	width: 300px;
}

.checkListProgressList__item:hover {
	background: #333;
	color: #fff;
}

.userHome__extensionInstallReminder {
	margin-top: 50px;
	padding: 30px 60px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.userHome__extensionInstallReminder > div:first-child {
	flex: 0.4;
}
.userHome__extensionInstallReminder > div:last-child {
	flex: 0.6;
	display: flex;
	align-items: center;
	justify-content: center;
}

.userHome__extensionInstallReminder .button {
	width: 300px;
	display: block;
	margin: 15px 0;
}

/******* Dark Mode *********/
.app.dark .checkListProgressList__item {
	color: #ffffff;
}
