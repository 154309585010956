.adminDash {
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
	margin: 0 auto;
	width: 100%;
}
.adminDash__menu {
	display: block;
	margin: 0 auto;
	width: 100%;
	padding: 0 15px;
	box-sizing: border-box;
}

.adminDash__menu ul {
	display: flex;
	justify-content: center;
	align-items: center;
	list-style-type: none;
}

.adminDash__menu ul li {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 15px;
	border: 1px solid #333;
	border-radius: 10px;
	cursor: pointer;
	margin: 0 15px;
	transition: all 200ms ease-in-out;
}

.adminDash__menu ul li:hover {
	background: #f2f2f2;
	border-color: #f2f2f2;
}

.adminDash__menu ul li.selected {
	background: #333;
	color: #fff;
}

.adminDash__menu ul li .MuiSvgIcon-root {
	width: 30px;
	object-fit: contain;
	height: auto;
	margin-right: 10px;
}

.adminDash__menu ul li h2 {
	font-size: 24px;
}

.adminDash__view {
	display: block;
	margin: 0 auto;
	width: 100%;
	padding: 15px 30px;
	box-sizing: border-box;
}
