* {
	margin: 0;
	padding: 0;
}

.app main {
	padding-left: 75px;
	width: 100%;
	display: flex;
	flex-direction: column;
	position: relative;
	min-height: 100vh;
	overflow-x: hidden;
}

.app.dark main {
	background: #333;
}

.app.dark main h1,
.app.dark main h2,
.app.dark main h3,
.app.dark main p,
.app.dark main td,
.app.dark main th {
	color: #fff !important;
}

.app.dark .sidebar {
	box-shadow: 0 3px 10px rgba(0, 155, 0, 0.5);
	background: #333;
}

.app.dark .footer {
	background: #333;
	color: #fff;
	box-shadow: 0 3px 10px rgba(0, 155, 0, 0.5);
}

.app.dark .footer a {
	color: #fff;
}

.app.dark main .header .dropDownAvatar__list {
	box-shadow: 0 3px 10px rgba(0, 155, 0, 0.5);
	background: #999;
	color: #fff;
}

.app.dark main .header .dropDownAvatar__list:before {
	border-bottom: 25px solid #999;
}

.app.dark main .summary__container .summary__card .summary__value {
	color: #fff;
}

.app.dark main .AffiliateDash__summary .summary__table .summary__row > div {
	color: #fff;
}

.app.dark main .AffiliateDash__summary .summary__container .summary__card {
	box-shadow: 0 3px 5px rgba(0, 155, 0, 0.5);
	border: 1px solid rgba(0, 155, 0, 0.5);
}

.app.dark main .properties__title,
.app.dark main .properties__value {
	color: #fff;
}

.app.dark main .settings__edit {
	color: #fff;
	border-color: #fff;
}

.app.dark main .subUser pre {
	color: #fff;
}

.app.dark .settingsSideBar {
	background: #555;
	color: #fff;
}

.app.dark .settingsSideBar .settingsSideBar__close {
	color: #fff;
}

.app.dark .settingsSideBar__body .body__userInfo .userInfo__details .details__name {
	color: #fff;
}

.app.dark .settingsSideBar .form__buttonGroup .edit__button {
	border-color: #fff;
	color: #fff;
}

.app.dark .settingsSideBar .form__buttonGroup div.cancel__button {
	color: rgb(255, 50, 0);
}

.app.dark .settingsSideBar .form__buttonGroup div.cancel__button:hover {
	opacity: 0.7;
}

.app.dark .settingsSideBar .form__buttonGroup .submit__button {
	background: rgb(0, 155, 0);
	color: #fff;
}

.app.dark .settingsSideBar .form__buttonGroup .submit__button:hover {
	background: #fff;
	color: rgb(0, 155, 0);
}
