.sidebar {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 75px;
	min-height: 100vh;
	box-shadow: 0 3px 10px rgba(0, 0, 0, 0.25);
	z-index: 2;
	background: #fff;
	position: fixed;
	left: 0;
	top: 0;
	bottom: 0;
}

.sidebar nav ul {
	list-style-type: none;
	display: flex;
	flex-direction: column;
	width: 100%;
	padding-left: 0;
	margin: 0;
}

.sidebar nav ul li {
	width: 100%;
	box-sizing: border-box;
	padding: 5px;
	margin: 10px auto;
	cursor: pointer;
}

.sidebar nav ul li .logo {
	width: 100%;
}

.sidebar nav ul li .logo a img {
	width: 100%;
}

.sidebar nav ul li .MuiSvgIcon-root {
	display: block;
	color: rgb(0, 155, 0);
	width: 50px;
	height: auto;
	object-fit: contain;
	margin: 0 auto;
}

.sidebar .nav__settings .MuiSvgIcon-root {
	display: block;
	color: rgb(0, 155, 0);
	width: 50px;
	height: auto;
	object-fit: contain;
	margin: 0 auto 15px auto;
}

.sidebar .nav__settings ul li {
	cursor: pointer;
}
