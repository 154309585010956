.footer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 15px;
    position: fixed;
    bottom: 0;
    left: 70px;
    right: 0;
    background: #fff;
    box-shadow: 0 3px 5px rgba(0,0,0,0.25);
    z-index: 1;
    width: calc(100% - 70px);
}

.footer .right{
    display: flex;
    align-items: center;
}

.footer .right a{
    margin-left: 10px;
    text-decoration: none;
    color: #333;
}