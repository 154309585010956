.manageUsers {
	box-sizing: border-box;
	width: 100%;
}

.manageUsers .manageUsers__title{
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.manageUsers__title form{
	display: flex;
	align-items: center;
}

.manageUsers__title form input{
	padding: 0.5em 1em;
	box-sizing: border-box;
	border-radius: 999px;
}

.manageUsers__title form input.search__input{
	margin-right: 15px;
	width: 300px;
}

.manageUsers__title form input:focus{
	outline: none;
}

.manageUsers__title form button{
	display: none;
}

.manageUsers__list {
	height: 40vh;
	overflow: auto;
}
.manageUsers__header {
	display: flex;
	align-items: center;
	font-weight: 700;
	font-size: 18px;
	background: rgb(0, 155, 0);
	padding: 5px 10px;
	color: #fff;
}

.manageUsers__item {
	display: flex;
	align-items: center;
	padding: 5px 10px;
	cursor: pointer;
}

.manageUsers__item:hover {
	background: rgba(0, 155, 0, 0.1);
}

.user__id {
	width: 50px;
}

.user__name {
	width: 200px;
}

.user__email {
	width: 300px;
}

.user__plans {
	width: 250px;
}

.user__permissions {
	width: 100px;
}

.user__actions {
	width: 200px;
}

.user__actions button {
	padding: 0.5em 0;
	text-align: center;
	width: 125px;
	font-size: 15px;
	font-weight: 600;
	background: transparent;
}

.manageUsers__detailedView h2 {
	border-bottom: 1px solid rgb(0, 155, 0);
}

.detailedView__container {
	display: flex;
	box-sizing: border-box;
	width: 100%;
	justify-content: space-between;
	align-items: flex-start;
}

.detailedView__left {
	flex: 0.7;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.detailedView__left .property {
	flex: 1;
	display: flex;
	align-items: center;
	width: 100%;
	margin: 10px 0;
}

.detailedView__left .property .title {
	width: 10em;
	font-size: 17px;
	font-weight: 700;
}

.detailedView__left .property .value ul {
	list-style-type: none;
	padding-left: 0;
}

.detailedView__right {
	box-sizing: border-box;
	padding: 15px 10px;
	flex: 0.3;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.detailedView__container button,
.actions__buttonPlaceHolder {
	width: 200px;
	padding: 0.75em 2em;
	box-sizing: border-box;
	background: transparent;
	margin: 10px auto;
	border: 0;
	border-radius: 5px;
	font-weight: 600;
	cursor: pointer;
	transition: all 200ms ease-in-out;
}

.actions__buttonPlaceHolder {
	border: 1px solid #999;
	color: #999;
	font-size: 13.333px;
}

.detailedView__container button.actions__assignAdmin {
	border: 1px solid rgb(0, 155, 0);
	color: rgb(0, 155, 0);
}

.detailedView__container button.actions__assignAdmin:hover {
	background: rgb(0, 155, 0);
	color: #fff;
}

.detailedView__container button.actions__removeAdmin {
	border: 1px solid rgb(200, 0, 0);
	color: rgb(200, 0, 0);
}

.detailedView__container button.actions__removeAdmin:hover {
	background: rgb(200, 0, 0);
	color: #fff;
}

.detailedView__container button.actions__cancelSubscription {
	border: 1px solid rgb(200, 0, 0);
	color: rgb(200, 0, 0);
}

.detailedView__container button.actions__cancelSubscription:hover {
	background: rgb(200, 0, 0);
	color: #fff;
}
