.AffiliateSignUp{
    display: block;
    padding: 15px;
    text-align: center;
    width: 100%;
    box-sizing: border-box;
}

.AffiliateSignUp__form{
    display: block;
    margin: 45px auto;
    width: 650px;
}
.AffiliateSignUp__form .form__row{
    display: flex;
    width: 100%;
}

.AffiliateSignUp__form .form__row input{
    flex-grow: 1;
    padding: 10px;
    font-size: 17px;
    margin: 5px;
}

.AffiliateSignUp__form .form__row input:focus{
    outline: 1px solid rgb(0,155,0);
}


.AffiliateSignUp__form .form__row.column{
    flex-direction: column;
    margin-top: 20px;
}

.AffiliateSignUp__form button{
    padding: 0.5em 3em;
    border: 1px solid rgb(0,155,0);
    color: rgb(0,155,0);
    background: #fff;
    font-size: 20px;
    border-radius: 999px;
    display: block;
    margin: 15px auto 0 auto;
    transition: all 200ms ease-in-out;
    cursor: pointer;
}

.AffiliateSignUp__form button:hover{
    background: rgb(0,155,0);
    color: #fff;
}

.AffiliateSignUp__form button.disable{
    opacity: 0.5;
    border-color: #999;
    color: #999;
    cursor: not-allowed;
}

.AffiliateSignUp__form button.disable:hover{
    background: transparent;
    color: #999;
}

.AffiliateSignUp__alert{
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 99;
    transform: translate(-50%,-50%);
    display: block;
    background: #fff;
    width: 450px;
    border: 1px solid rgb(155,0,0);
    color: rgb(155,0,0);
    text-align: center;
    font-size: 20px;
    padding: 45px 15px;
    border-radius: 10px;
    box-sizing: border-box;
    box-shadow: 0 3px 10px rgba(0,0,0,0.25);
}

.AffiliateSignUp__alert button{
    padding: 0.5em 3em;
    display: block;
    margin: 15px auto 0 auto;
    border: 1px solid rgba(155,0,0,0.5);
    background: #fff;
    color: rgb(155,0,0);
    cursor: pointer;
}

.AffiliateSignUp__thankYou{
    padding: 15px;
    box-sizing: border-box;
    text-align: center;
    max-width: 500px;
    display: block;
    margin: 0 auto;
}

.AffiliateSignUp__thankYou h2{
    margin-bottom: 15px;
    font-size: 36px;
}

.AffiliateSignUp__thankYou p a{
    color: rgb(0,155,0);
    text-decoration: none;
    font-weight: 500;
}

@media screen and (max-width: 650px){
    .AffiliateSignUp__form{
        display: flex;
        flex-direction: column;
        width: 90%;
        box-sizing: border-box;
    }

    .AffiliateSignUp__form .form__row{
        flex-direction: column;
    }

    .AffiliateSignUp__form .form__row p{
        font-size: 14px;
        font-weight: 500;
    }
}