.affiliateReconciliation {
	padding: 0 15px;
}

.affiliateReconciliation__table {
	display: block;
	margin: 30px auto;
	width: 100%;
	box-sizing: border-box;
}

.affiliateReconciliation__table .table__header {
	display: flex;
	justify-content: space-between;
	align-items: baseline;
	border-bottom: 2px solid #333;
}

.affiliateReconciliation__table .table__header .header__value {
	font-weight: 700;
	font-size: 18px;
	text-align: center;
	width: 150px;
}

.affiliateReconciliation__table .table__body {
	display: flex;
	flex-direction: column;
	height: 50vh;
	overflow-y: auto;
	overflow-x: hidden;
}

.affiliateReconciliation__table .table__body .body__row {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px 0;
}

.affiliateReconciliation__table .table__body .body__row .row__value {
	text-align: center;
	width: 150px;
}
.affiliateReconciliation__table .table__buttonGroup.hide {
	height: 0;
	overflow: hidden;
}
.affiliateReconciliation__table .table__buttonGroup.show {
	display: flex;
	height: inherit;
	margin: 0 0 0 auto;
	width: 33%;
	justify-content: flex-end;
	transition: all 2s ease-in-out;
}

.affiliateReconciliation__table .table__buttonGroup .button {
	margin-left: 10px;
}

.affiliateReconciliation__summary h2 {
	color: rgb(0, 155, 0);
	border-bottom: 1px solid rgb(0, 155, 0);
	margin-bottom: 15px;
}

.affiliateReconciliation__summary .summary__container {
	display: flex;
	justify-content: center;
	align-items: center;
}

.affiliateReconciliation__summary .summary__container .summary__box {
	padding: 15px;
	box-sizing: border-box;
	margin: 0 15px;
	background: #fff;
	box-shadow: 0 3px 10px rgba(0, 0, 0, 0.25);
	border-radius: 10px;
	width: 200px;
	text-align: center;
}
