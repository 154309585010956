.dashboard_info{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.dashboard_info .dashboard_item{
    box-sizing: border-box;
    padding: 20px;
    border: 1px solid #333;
    border-radius: 10px;
    box-shadow: 0 3px 10px rgba(0,0,0,0.25);
    margin: 15px;
    min-width: 200px;
    text-align: center;
}

.dashboard_info .dashboard_item .item_row{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0;
}

.dashboard_info .dashboard_item .item_row .label{
    width: 7em;
    text-align: left;
    font-weight: 700;
}

.dashboard_info .dashboard_item .item_row .value{
    width: 5em;
    text-align: left;   
}

.dashboard_info .dashboard_item .item_row .yearly_figure{
    width: 10em;
    text-align: left;   
    font-style: italic;
    color: rgb(0,155,0);
}

.dashboard_info .dashboard_item .item_row .yearly_label{
    font-weight: 700;
    width: 12em;
    text-align: left;
}

.dashboard_info .dashboard_item .item_row:last-child .yearly_figure{
    font-weight: 700;
}