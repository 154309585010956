.settingsSideBar {
	position: fixed;
	top: 0;
	bottom: 0;
	background: #fff;
	box-shadow: 0 -3px 5px rgba(0, 0, 0, 0.25);
	width: 30%;
	min-width: 350px;
	right: 0;
	z-index: 10;
	transition: all 0.5s ease-in-out;
}

.settingsSideBar__close {
	position: absolute;
	top: 15px;
	right: 15px;
	color: #999;
	cursor: pointer;
}

.settingsSideBar__body {
	padding: 60px 15px 30px 15px;
	display: block;
}

.settingsSideBar.hide {
	right: -50%;
}

.settingsSideBar.show {
	right: 0;
}

.settingsSideBar__body .body__userInfo {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.settingsSideBar__body .body__userInfo img {
	max-width: 80px;
	object-fit: contain;
}

.settingsSideBar__body .body__userInfo .userInfo__details {
	text-align: right;
}

.settingsSideBar__body .body__userInfo .userInfo__details .details__name {
	font-weight: 500;
	font-size: 17px;
	color: #333;
}

.settingsSideBar__body .body__userInfo .userInfo__details .details__email {
	color: rgb(0, 155, 0);
}

.settingsSideBar__body .body__userInfo .userInfo__details .peronsalInfo__form {
	display: flex;
	flex-direction: column;
}

.settingsSideBar__body .body__userInfo .userInfo__details .peronsalInfo__form input {
	margin: 5px 0 5px auto;
}

.settingsSideBar .form__buttonGroup .edit__button {
	padding: 3px 10px;
	border: 1px solid #333;
	color: #333;
	text-align: center;
	box-sizing: border-box;
	font-size: 15px;
	font-weight: 500;
	cursor: pointer;
	transition: all 200ms ease-in-out;
	width: 100%;
}

.settingsSideBar__body .body__editButton:hover {
	background: #333;
	color: #fff;
}

.userInfo__details form {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.userInfo__details input {
	margin: 5px auto;
	padding: 5px;
	box-sizing: border-box;
	width: 170px;
}

.settingsSideBar .form__buttonGroup .submit__button {
	padding: 5px 10px;
	border: 1px solid rgb(0, 155, 0);
	color: rgb(0, 155, 0);
	width: 60%;
	text-align: center;
	background: #fff;
	cursor: pointer;
	transition: all 200ms ease-in-out;
	font-weight: 500;
}

.settingsSideBar .form__buttonGroup .submit__button:hover {
	background: rgb(0, 155, 0);
	color: #fff;
}

.settingsSideBar .form__buttonGroup {
	display: flex;
	width: 100%;
	padding: 5px;
	box-sizing: border-box;
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
}

.settingsSideBar .form__buttonGroup div.cancel__button {
	flex-grow: 1;
	margin: 0 0 0 5px;
	text-align: center;
	color: rgb(155, 0, 0);
	cursor: pointer;
}

.settingsSideBar__body .body__referrer,
.settingsSideBar__body .body__darkMode,
.settingsSideBar__body .body__apiKey {
	display: flex;
	margin-top: 30px;
	width: 100%;
	justify-content: space-between;
	align-items: center;
}

.settingsSideBar__body .body__referrer .referrer__title {
	display: flex;
	align-items: center;
}

.settingsSideBar__body .body__referrer .referrer__title .tooltip {
	position: relative;
	width: 15px;
	height: 15px;
	border: 1px solid #333;
	text-align: center;
	line-height: 15px;
	font-size: 14px;
	margin-left: 5px;
	border-radius: 50%;
	cursor: pointer;
}

.settingsSideBar__body .body__referrer .referrer__title .tooltip .tooltip__info {
	display: none;
	transition: all 200ms ease-in-out;
	position: absolute;
	top: 50%;
	right: -215px;
	background: #333;
	color: #fff;
	padding: 15px 10px;
	border-radius: 5px;
	width: 200px;
	box-shadow: 0 3px 5px rgba(0, 0, 0, 0.25);
}

.settingsSideBar__body .body__referrer .referrer__title .tooltip:hover .tooltip__info {
	display: block;
}

.settingsSideBar__body .body__referrer .referrer__value select,
.settingsSideBar__body .body__darkMode .darkMode__value select {
	padding: 5px;
	width: 170px;
	cursor: pointer;
}
