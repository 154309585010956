.header {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	box-sizing: border-box;
	padding: 10px 15px;
	width: 100%;
}

.header .logo {
	border-radius: 50%;
	overflow: hidden;
	height: 100px;
	width: 100px;
	text-align: center;
}

.header .logo img {
	object-fit: contain;
	width: 100px;
}

.header .nav nav .navbar-nav {
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	list-style-type: none;
}

.header .nav nav .navbar-nav li {
	margin: 0 8px;
	color: #333;
	font-size: 18px;
}

.header .nav nav .navbar-nav li a {
	text-decoration: none;
	color: #333;
	font-size: 18px;
}

.header .nav nav .navbar-nav li img {
	cursor: pointer;
	transition: all 200ms ease-in-out;
	max-width: 75px;
	object-fit: contain;
}

.header .nav nav .navbar-nav li img:hover {
	opacity: 0.7;
}

.header .header__freeTrial {
	display: flex;
	align-items: center;
	flex-direction: column;
	flex-grow: 1;
	justify-content: center;
	background: linear-gradient(
		to right,
		transparent,
		rgba(0, 155, 0) 25%,
		rgba(0, 155, 0) 75%,
		transparent
	);
	padding: 15px 0;
	color: #fff;
	font-weight: 500;
	font-size: 18px;
}

.header .header__freeTrial .countDownTimer {
	margin-top: 10px;
}
