.affiliateDash{
    padding: 0 15px;
}

.affiliateDash h1{
    margin-bottom: 30px;
}

.affiliateDash__table{
    display: block;
    margin: 30px auto;
    width: 100%;
}
.affiliateDash__table h2{
    margin-bottom: 15px;
    color: rgb(0,155,0);
    border-bottom: 1px solid rgb(0,155,0);
}

.affiliateDash__table .table__head{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 700;
    font-size: 18px;
    color: #333;
    border-bottom: 2px solid #333;
    padding: 15px 0;
    box-sizing: border-box;
}

.affiliateDash__table .table__head .table__header{
    width: 300px;
    text-align: center;
}

.affiliateDash__table .table__body{
    max-height: 40vh;
    overflow-y: auto;
}

.affiliateDash__table .table__body .table__row{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    box-sizing: border-box;
    border-bottom: 1px dashed #333;
}

.affiliateDash__table .table__body .table__row .table__data{
    width: 300px;
    text-align: center;
}

.AffiliateDash__summary{
    display: block;
    margin: 30px 0;
    width: 100%;
}

.AffiliateDash__summary h2{
    margin-bottom: 15px;
    color: rgb(0,155,0);
    border-bottom: 1px solid rgb(0,155,0);
}

.AffiliateDash__summary .summary__container{
    display: flex;
    justify-content: center;
    align-items: center;
}

.AffiliateDash__summary .summary__container .summary__card{
    padding: 15px;
    box-sizing: border-box;
    margin: 15px 20px;
    border: 1px solid #333;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0 3px 5px rgba(0,0,0,0.25);
    min-width: 200px;
}

.AffiliateDash__summary .summary__table .summary__row{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.AffiliateDash__summary .summary__table .summary__row:first-child{
    font-weight: 700;
    border-bottom: 2px solid #333;
}

.AffiliateDash__summary .summary__table .summary__row > div{
    width: 200px;
    padding: 10px 0;
}

.AffiliateDash__summary .summary__table .summary__row:nth-child(n + 2){
    border-bottom: 1px dashed #999;
}

.AffiliateDash__summary .summary__table .summary__row:last-child{
    border-bottom: 0;
}
